import { CreateControllerFn, IUser } from '@wix/yoshi-flow-editor';
import { renderMenus, setConfigGlobally, setCurrentUserGlobally } from '../../viewer-app-module';
import { toMonitored } from '../../viewer-app-module/utils/monitoring';
import { setMobileMembersMenuValue } from '../../viewer-app-module/services/menu-renderer-editor';
import { AppData } from '../../viewer-app-module/types';
import {
  APP_WIDGET_LOGIN_MENU_ID,
  APP_WIDGET_MEMBERS_MENU_ID,
  LOGIN_MENU_ID,
  MEMBERS_MENU_ID,
} from '../../viewer-app-module/constants';

const createController: CreateControllerFn = async ({ controllerConfig, appData, flowAPI }) => {
  const {
    wixCodeApi,
    essentials: { httpClient },
    $w,
  } = controllerConfig;

  const hasLoginMenus = !!$w(LOGIN_MENU_ID).length;
  const hasAppWidgetsLoginMenus = !!$w!(APP_WIDGET_LOGIN_MENU_ID).length;
  const hasMemberMenus = !!$w(MEMBERS_MENU_ID).length;
  const hasAppWidgetsMenus = !!$w(APP_WIDGET_MEMBERS_MENU_ID).length;
  const hasMenus = hasLoginMenus || hasAppWidgetsLoginMenus || hasMemberMenus || hasAppWidgetsMenus;

  if (!hasMenus) {
    return {
      pageReady() {},
    };
  }

  const isInEditor = wixCodeApi.window.viewMode === 'Editor';

  setConfigGlobally(controllerConfig);

  wixCodeApi.user.onLogin((loggedInUser: IUser) =>
    toMonitored('onLogin', () =>
      setCurrentUserGlobally(loggedInUser, httpClient).then(() => renderMenus(controllerConfig, appData as AppData)),
    )(),
  );

  return {
    async pageReady() {
      if (isInEditor) {
        if (flowAPI.environment.isMobile) {
          setMobileMembersMenuValue($w, wixCodeApi, (appData as AppData).parsedRouters);
        }
      } else {
        renderMenus(controllerConfig, appData as AppData);
      }
    },
  };
};

export default createController;
